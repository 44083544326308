@import 'styles/_var';

.navbarWrap {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  padding: 0 4.5%;
  background: linear-gradient(119deg, #232d40, #171e2d);
  // background-color: rgba(33, 42, 60, 0.8);
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  z-index: 4;
}

.desktopNav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $navbarHeight;

  .logo svg {
    display: block;
    width: 100%;
    min-height: 40px;
  }

  .rightNav {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 30px;

    .flexLink {
      list-style-type: none;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 20px;

      li {
        display: block;

        & a {
          display: block;
          width: fit-content;
          padding: 0 10px;
          font-size: 0.9rem;
          text-align: center;
          transition: color 0.3s ease;

          &:hover {
            color: $gold;
          }
        }

        // &:nth-child(1) {
        //   & a {
        //     width: 120px;
        //     text-align: left;
        //   }
        // }
      }
    }

    .flexBtn {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .selectDropdown {
      position: relative;
      display: block;
      background: transparent;
      border: none;
      padding: 0;
      min-width: 60px;
      line-height: unset !important;

      &:focus,
      &:focus-visible {
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
        border: none !important;
      }
      & option {
        width: 100%;
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
