@import 'styles/_var';

.Layout {
  background: linear-gradient(145deg, #232d40, #171e2d);
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    margin-top: $navbarHeight;
  }

  .Footer {
    position: relative;
    display: block;
    width: 100%;
  }
}
