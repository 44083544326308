@import 'styles/_var';

.mobNav {
  position: relative;
  display: flex;
  height: $navbarHeight;
  align-items: center;

  .navBtn {
    position: relative;
    display: block;
    background: linear-gradient(106deg, #2c4474 0%, #152544 100%);
    border-radius: 8px;
    text-align: center;
    border: none;
  }

  .logo svg {
    width: 100%;
    min-height: 40px;
  }
  .menuSide {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: auto;
  }
  .btnSide {
    display: flex;
    margin-left: auto;
    gap: 10px;
    align-items: center;
  }

  .selectDropdown {
    position: relative;
    display: block;
    background: transparent;
    text-align: center;
    border: none;
    line-height: unset !important;
    height: 45px;
  }

  @media screen and (max-width: 768px) {
    [class*='chakra-select__icon-wrapper'] {
      right: -0.3rem !important;
    }

    .selectDropdown {
      font-size: 14px;
      padding: 0 4px;
      text-align: left;
      min-width: 40px;
    }
  }
  @media screen and (min-width: 1001px) {
    display: none;
  }
}

.showMobile {
  display: block;
  @media screen and (min-width: 1001px) {
    display: none;
  }
}
.showDesktop {
  display: block;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}