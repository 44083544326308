.footer {
  position: relative;
  width: 100%;
  padding: 20px 4.5% 50px;
  background-color: #181f2f;

  & p {
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    text-align: center;
  }

  .iconContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 30px 0;

    & a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 60px;
      height: 60px;
      border-radius: 300px;
      text-align: center;
      font-size: 24px;
      background: linear-gradient(314deg, #232d40 0%, #171e2d 100%);

      &:hover {
        background: linear-gradient(
          to right,
          var(--card-gradient-2),
          var(--card-gradient-1)
        );
      }
    }
  }
  .footerLinkContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & a {
      font-size: 18px;
      padding: 5px 30px;
    }

    & a:nth-child(2) {
      border-left: 2px solid rgba(172, 201, 216, 0.5);
      border-right: 2px solid rgba(172, 201, 216, 0.5);
    }
  }
  @media screen and (max-width: 768px) {
    & p {
      font-size: 18px;
    }
    .iconContainer {
      margin: 20px 0 30px;
      & a {
        width: 40px;
        height: 40px;
        padding: 5px;
        font-size: 16px;
      }
    }
    .footerLinkContainer {
      & a {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    & p {
      font-size: 18px;
    }
    .iconContainer {
      margin: 20px 0 30px;
      & a {
        width: 40px;
        height: 40px;
        padding: 5px;
        font-size: 16px;
      }
    }
    .footerLinkContainer {
      width: 100%;
      & a {
        padding: 5px 10px;
      }
    }
  }
}
